export const PATH = {
  shop: '/shop',
  shopNewArrivals: '/shop/new-arrivals',
  gifts: '/gifts',
  recycle: '/recycle',
  blogs: '/your-dose-of-aurev',
  ourStory: '/our-story',
  aboutUs: '/about-us',
  ourEthos: '/our-ethos',
  home: '/home',
  wishlist: '/wishlist',
  tos: '/tos',
  privacy: '/privacy',
  bespoke: '/bespoke',

  myAccount: '/collection',
  myAccountOrders: '/collection/orders',
  myAccountProfile: '/collection/profile',
  myAccountOrderDetails: '/collection/orders/[orderId]',
  myAccountGuest: '/collection/guest',

  collections: '/collections',

  categories: '/categories',

  cartCheckout: '/cart/checkout',
  cartCheckoutSuccess: '/cart/checkout/success',

  search: '/search',
  landing: '/',

  faq: '/faq',
  tags: '/t',
  giftCard: '/gift-card',
  all: '/all',
};

export const SHOP_PATH_SLUG = {
  newArrivals: 'new-arrivals',
  allProducts: 'all-products',
  recommended: 'recommended',
  trending: 'trending',
};
